var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"sm":"12","cols":"12"}},[_c('div',{staticStyle:{"background-color":"#fff","padding":"20px 20px","border-radius":"10px"}},[_c('v-col',{attrs:{"sm":"12","cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"sm":"12","cols":"12"}},[_c('h2',{staticClass:"mb-0",staticStyle:{"font-size":"25px","font-weight":"550"}},[_vm._v(" "+_vm._s(this.$route.params.name_en)+" ")]),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"right":""},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")]),_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"float-right",staticStyle:{"background-color":"#5de99e"}},on),[_c('span',{staticStyle:{"font-size":"1em","color":"#000000"}},[_c('i',{staticClass:"fas fa-user-plus"},[_c('label',{staticClass:"ml-2 btn_create_new"},[_vm._v(_vm._s(_vm.$t("create_subject")))])])])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"color":"teal accent-3"}},[(this.subjectId != '')?_c('v-card-title',[_vm._v(_vm._s(_vm.$t("edit_subject")))]):_c('v-card-title',[_vm._v(_vm._s(_vm.$t("create_subject")))]),_c('v-icon',{staticClass:"btn_close",on:{"click":function($event){_vm.dialog = false;
                        _vm.clear();
                        _vm.resetValidation();}}},[_vm._v("close")]),_c('v-divider'),_c('v-card-text',{staticStyle:{"background-color":"#edf1f5","color":"#333"}},[_c('v-container',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"sm":"12","cols":"12"}},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("name")))]),_c('v-text-field',{attrs:{"disabled":this.subjectId != '',"placeholder":"e.g Data Science","rules":[_vm.checkDuplicate, _vm.rules.required],"precision":"3","outlined":"","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("credit")))]),_c('v-text-field',{attrs:{"placeholder":"5","outlined":"","required":""},model:{value:(_vm.credit),callback:function ($$v) {_vm.credit=$$v},expression:"credit"}})],1),_c('v-card',[_c('v-card-title',{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.$t("assign_lecturer")))]),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"max-height":"400px","overflow-y":"auto"},attrs:{"headers":_vm.headers,"items":_vm.listLect,"items-per-page":25,"footer-props":{
                                  'items-per-page-options': [10, 25, 50, -1],
                                },"item-key":"id","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('thead',[_c('tr',[_c('th',[_c('label',{staticClass:"form-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectAll),expression:"selectAll"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.selectAll)?_vm._i(_vm.selectAll,null)>-1:(_vm.selectAll)},on:{"click":_vm.select,"change":function($event){var $$a=_vm.selectAll,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectAll=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectAll=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectAll=$$c}}}}),_c('i',{staticClass:"form-icon"})])]),_c('th',[_vm._v(_vm._s(_vm.$t("name_en")))])])])]}},{key:"body",fn:function(ref){
                                var items = ref.items;
return [_c('tbody',{staticStyle:{"white-space":"nowrap"}},_vm._l((items),function(lect,index){return _c('tr',{key:index},[_c('td',[_c('label',{staticClass:"form-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],attrs:{"type":"checkbox"},domProps:{"value":lect.id,"checked":Array.isArray(_vm.selected)?_vm._i(_vm.selected,lect.id)>-1:(_vm.selected)},on:{"change":function($event){var $$a=_vm.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=lect.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selected=$$c}}}}),_c('i',{staticClass:"form-icon"})])]),_c('td',[_vm._v(" "+_vm._s(lect.title)+" "+_vm._s(lect.name_en)+" ")])])}),0)]}}])})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{staticClass:"btn_cancel",on:{"click":function($event){(_vm.dialog = false), _vm.clear(), _vm.resetValidation()}}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('v-spacer'),_c('v-btn',{staticClass:"btn_save_close",attrs:{"disabled":!_vm.valid},on:{"click":function($event){$event.preventDefault();_vm.onSaveClose(), _vm.validate()}}},[_vm._v(_vm._s(_vm.$t("save_close"))+" ")])],1)],1)],1),_c('v-btn',{staticClass:"float-right mr-5",staticStyle:{"background-color":"#00bcd4"},attrs:{"disabled":_vm.someIsNotZero == true || _vm.checked == false},on:{"click":function($event){return _vm.$router.push({
                      name: 'ManageSessionScore',
                      params: { sess_id: _vm.sess_id },
                    })}}},[_c('span',{staticStyle:{"font-size":"1em","color":"#000000"}},[_c('v-icon',[_vm._v(" mdi-counter ")]),_c('label',{staticClass:"ml-2 btn_create_new"},[_vm._v(_vm._s(_vm.$t("generate_session_score")))])],1)])],1)],1),_c('div',{staticStyle:{"margin-top":"20px"}}),_c('v-breadcrumbs',{attrs:{"items":_vm.items,"href":_vm.items.href},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])}),(_vm.listRowCount > 0)?_c('v-row',_vm._l((_vm.listSubject),function(sub,i){return _c('v-col',{key:i,attrs:{"sm":"4","cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"color":"#afcdfb","dark":"","max-width":"550"},on:{"click":function($event){return _vm.$router.push({
                      name: 'ManageScore',
                      params: {
                        id: sub.id,
                        session_id: sub.session_id,
                        name: sub.name,
                      },
                    })}}},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"float":"right"},attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#000000"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.onEditItem(sub)}}},[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.deletesubject(sub)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1),_c('v-card-title',{staticStyle:{"color":"#000000"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-h6 text-truncate"},[_vm._v(" "+_vm._s(sub.name)+" ")])])]),_c('v-card-subtitle',{staticStyle:{"font-weight":"400","color":"#000000"}},_vm._l((sub.lecturer),function(lect,index){return _c('v-chip',{key:index,staticClass:"ma-1",attrs:{"color":"indigo","label":"","text-color":"black","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-circle-outline ")]),_c('div',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(lect)+" ")])],1)}),1),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":'major-admin/manage/generations/sessions/subject/score' +
                      sub.id +
                      '&' +
                      sub.session_id +
                      '&' +
                      sub.name,"tag":"button"}}),_c('v-card-actions',{staticStyle:{"background-color":"#a0a3fb"}})],1)],1)}),1):_c('v-row',{staticClass:"mb-5",attrs:{"align":"center","justify":"center"}},[_c('div',[_c('v-img',{staticClass:"center",attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Fempty.png?alt=media&token=15a9dbf8-dca3-4df9-981d-f621d354e4ae"}}),_c('div',{staticClass:"text-h6 text-center"},[_vm._v(" There is no data for you to see yet ")]),_c('div',{staticClass:"text-h6 text-center"},[_vm._v(" If you want to create new subject , just click ")]),_c('div',{staticClass:"text-h6 text-center"},[_c('v-btn',{staticStyle:{"background-color":"#5de99e"},attrs:{"disabled":""}},[_c('span',{staticStyle:{"font-size":"1em","color":"#000000"}},[_c('i',{staticClass:"fas fa-user-plus"},[_c('label',{staticClass:"ml-2 btn_create_new"},[_vm._v(_vm._s(_vm.$t("create_subject")))])])])]),_vm._v(" button and go ahead. ")],1)],1)])],1)],1)])],1)],1),_c('div',[_c('div',{staticClass:"loading"},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":_vm.fullPage,"opacity":0.9,"background-color":"#dedede","width":40,"height":40},on:{"update:active":function($event){_vm.isLoading=$event}}},[(_vm.myLoading)?_c('div',[_c('img',{attrs:{"width":"100%","src":"https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"}})]):_vm._e()])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }