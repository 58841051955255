<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="12" cols="12" class="py-0">
                  <h2 class="mb-0" style="font-size: 25px; font-weight: 550">
                    {{ this.$route.params.name_en }}
                  </h2>
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>

                  <v-dialog v-model="dialog" max-width="500px" persistent>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        class="float-right"
                        style="background-color: #5de99e"
                      >
                        <span style="font-size: 1em; color: #000000">
                          <i class="fas fa-user-plus">
                            <label class="ml-2 btn_create_new">{{
                              $t("create_subject")
                            }}</label>
                          </i>
                        </span>
                      </v-btn>
                    </template>

                    <v-card color="teal accent-3">
                      <v-card-title v-if="this.subjectId != ''">{{
                        $t("edit_subject")
                      }}</v-card-title>
                      <v-card-title v-else>{{
                        $t("create_subject")
                      }}</v-card-title>

                      <v-icon
                        class="btn_close"
                        @click="
                          dialog = false;
                          clear();
                          resetValidation();
                        "
                        >close</v-icon
                      >
                      <v-divider />
                      <v-card-text
                        style="background-color: #edf1f5; color: #333"
                      >
                        <v-container>
                          <v-form ref="form" v-model="valid">
                            <v-row>
                              <v-col sm="12" cols="12" class>
                                <label class="label">{{ $t("name") }}</label>
                                <v-text-field
                                  v-model="name"
                                  :disabled="this.subjectId != ''"
                                  placeholder="e.g Data Science"
                                  :rules="[checkDuplicate, rules.required]"
                                  precision="3"
                                  outlined
                                  required
                                />
                                <label class="label">{{ $t("credit") }}</label>
                                <v-text-field
                                  v-model="credit"
                                  placeholder="5"
                                  outlined
                                  required
                                />
                              </v-col>

                              <v-card>
                                <v-card-title color="white">{{
                                  $t("assign_lecturer")
                                }}</v-card-title>

                                <v-divider />
                                <v-data-table
                                  :headers="headers"
                                  :items="listLect"
                                  :items-per-page="25"
                                  :footer-props="{
                                    'items-per-page-options': [10, 25, 50, -1],
                                  }"
                                  style="max-height: 400px; overflow-y: auto"
                                  item-key="id"
                                  hide-default-header
                                  class="elevation-1"
                                >
                                  <template v-slot:header="{}">
                                    <thead>
                                      <tr>
                                        <th>
                                          <label class="form-checkbox">
                                            <input
                                              type="checkbox"
                                              v-model="selectAll"
                                              @click="select"
                                            />
                                            <i class="form-icon"></i>
                                          </label>
                                        </th>

                                        <th>{{ $t("name_en") }}</th>
                                      </tr>
                                    </thead>
                                  </template>
                                  <template v-slot:body="{ items }">
                                    <tbody style="white-space: nowrap">
                                      <tr
                                        v-for="(lect, index) in items"
                                        v-bind:key="index"
                                      >
                                        <td>
                                          <label class="form-checkbox">
                                            <input
                                              type="checkbox"
                                              :value="lect.id"
                                              v-model="selected"
                                            />
                                            <i class="form-icon"></i>
                                          </label>
                                        </td>

                                        <td>
                                          {{ lect.title }} {{ lect.name_en }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-data-table>
                              </v-card>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-btn
                          class="btn_cancel"
                          @click="(dialog = false), clear(), resetValidation()"
                          >{{ $t("cancel") }}</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                          class="btn_save_close"
                          :disabled="!valid"
                          @click.prevent="onSaveClose(), validate()"
                          >{{ $t("save_close") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-btn
                    :disabled="someIsNotZero == true || checked == false"
                    class="float-right mr-5"
                    style="background-color: #00bcd4"
                    @click="
                      $router.push({
                        name: 'ManageSessionScore',
                        params: { sess_id: sess_id },
                      })
                    "
                  >
                    <span style="font-size: 1em; color: #000000">
                      <v-icon> mdi-counter </v-icon>
                      <label class="ml-2 btn_create_new">{{
                        $t("generate_session_score")
                      }}</label>
                    </span>
                  </v-btn>
                </v-col>
              </v-row>

              <div style="margin-top: 20px"></div>
              <v-breadcrumbs :items="items" :href="items.href">
                <template v-slot:divider>
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
              </v-breadcrumbs>

              <v-row v-if="listRowCount > 0">
                <v-col
                  sm="4"
                  cols="12"
                  v-for="(sub, i) in listSubject"
                  :key="i"
                >
                  <v-card
                    color="#afcdfb"
                    dark
                    max-width="550"
                    class="mx-auto"
                    @click="
                      $router.push({
                        name: 'ManageScore',
                        params: {
                          id: sub.id,
                          session_id: sub.session_id,
                          name: sub.name,
                        },
                      })
                    "
                  >
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          style="float: right"
                          v-on="on"
                        >
                          <v-icon color="#000000">mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item link @click="onEditItem(sub)">
                          <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="deletesubject(sub)">
                          <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-card-title style="color: #000000">
                      <div class="row">
                        <div class="col-12 text-h6 text-truncate">
                          {{ sub.name }}
                        </div>
                      </div>
                    </v-card-title>

                    <v-card-subtitle style="font-weight: 400; color: #000000">
                      <v-chip
                        v-for="(lect, index) in sub.lecturer"
                        class="ma-1"
                        color="indigo"
                        label
                        text-color="black"
                        outlined
                        :key="index"
                      >
                        <v-icon left> mdi-account-circle-outline </v-icon>
                        <div class="subtitle-2">
                          {{ lect }}
                        </div>
                      </v-chip>
                    </v-card-subtitle>
                    <router-link
                      style="text-decoration: none"
                      :to="
                        'major-admin/manage/generations/sessions/subject/score' +
                        sub.id +
                        '&' +
                        sub.session_id +
                        '&' +
                        sub.name
                      "
                      tag="button"
                    >
                    </router-link>

                    <v-card-actions style="background-color: #a0a3fb">
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else align="center" justify="center" class="mb-5">
                <div>
                  <v-img
                    src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Fempty.png?alt=media&token=15a9dbf8-dca3-4df9-981d-f621d354e4ae"
                    class="center"
                  ></v-img>
                  <div class="text-h6 text-center">
                    There is no data for you to see yet
                  </div>
                  <div class="text-h6 text-center">
                    If you want to create new subject , just click
                  </div>
                  <div class="text-h6 text-center">
                    <v-btn disabled style="background-color: #5de99e">
                      <span style="font-size: 1em; color: #000000">
                        <i class="fas fa-user-plus">
                          <label class="ml-2 btn_create_new">{{
                            $t("create_subject")
                          }}</label>
                        </i>
                      </span>
                    </v-btn>
                    button and go ahead.
                  </div>
                </div>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>
<script>
import Loading from "vue-loading-overlay";
import store from "@/store";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  getSubjectById,
  createSubject,
  updateSubject,
  deleteSubject,
  getLecturer,
  getSessionScoreBySessionId,
  checkSubjectName,
} from "@schoolbase/web-client-lib";
export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data() {
    return {
      rules: {
        required: (v) => !!v || "this field is required",
      },
      listSubject: [],
      selected: [],
      isLoading: false,
      valid: true,
      form: false,
      token: store.getters.getToken,
      myLoading: false,
      selectAll: false,
      subjectId: "",
      checked: "",
      snackbar: {
        show: false,
        message: null,
        color: null,
      },

      items: [
        {
          text: "Generation",
          disabled: false,
          href: "javascript:history.go(-1)",
        },
        {
          text: "Session",
          disabled: false,
          href: "javascript:history.go(-1)",
        },
        {
          text: "Subject",
          disabled: true,
        },
      ],
      headers: [{ text: "English Name", value: "name_en" }],
      listLect: [],
      sess_id: this.sess_id,
      dialog: false,
      dialog1: false,
      session_id: "",
      credit: "",
      data: "",
      lecturer_ids: "",
      listRowCount: [],
      ListCredit: [],
      listSessionScore: [],
      listHeader: [],
      name: null,
      nameValid: [],
      someIsNotZero: "",
      majorId: "",
    };
  },
  components: {
    Loading,
  },
  computed: {},
  methods: {
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.listLect) {
          this.selected.push(this.listLect[i].id);
        }
      }
    },
    checkDuplicate(val) {
      if (val) {
        if (this.subjectId == "") {
          const data = this.nameValid.find((element) => {
            return (
              element.trim().toLowerCase() === this.name.trim().toLowerCase()
            );
          });

          if (data) {
            if (val.trim().toLowerCase() === data.trim().toLowerCase()) {
              return `Subject Name "${val}" Already Exists`;
            } else {
              return true;
            }
          }
        }
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onEditItem(item) {
      this.name = item.name;
      this.selected = item.lecturer_ids;
      this.session_id = item.session_id;
      this.credit = item.credit;
      this.subjectId = item.id;
      this.dialog = true;
    },
    async checkSubjectName() {
      const subjectName = await checkSubjectName(
        this.$route.params.gen_id,
        this.token
      );
      this.nameValid = subjectName.payload;
    },
    // Clear Data After User click cancel or dialoge is false
    clear() {
      this.name = "";
      this.credit = "";
      this.id = "";
      this.selected = [];
      this.selectAll = "";
      this.session_id = "";
      this.lecturer_ids = "";
      this.subjectId = "";
    },

    //Create User Function

    async onSaveClose() {
      let data = {
        name: this.name,
        session_id: this.$route.params.id,
        credit: this.credit,
        lecturer_ids: this.selected,
      };
      if (this.subjectId) {
        if (this.subjectId !== "") {
          try {
            await updateSubject(this.subjectId, data);
            this.loadSubject();
            this.checkSubjectName();
          } catch (e) {
            this.snackbar = {
              message: e,
              color: "error",
              show: true,
            };
          }
        }
      } else {
        try {
          await createSubject(data);
          this.loadSubject();
          this.checkSubjectName();
        } catch (e) {
          this.snackbar = {
            message: e,
            color: "error",
            show: true,
          };
        }
      }
      this.resetValidation();
      this.clear();

      this.dialog = false;
    },
    async loadSubject() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await getSubjectById(
          this.$route.params.id,
          this.token
        );
        this.listSubject = APIResponse.payload.rows.sort(function (a, b) {
          var nameA = a.name;
          var nameB = b.name;
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        this.listRowCount = APIResponse.payload.rowCount;
        let generate = APIResponse.payload.rows.map(
          (item) => item.score_approval
        );
        this.someIsNotZero = generate.some((item) => item == 0);
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }

      if (this.listRowCount > 0) {
        return (this.checked = true);
      } else {
        return (this.checked = false);
      }
    },
    async loadSessionScore() {
      try {
        const APIResponse = await getSessionScoreBySessionId(
          this.$route.params.id,
          this.token
        );

        this.listHeader = APIResponse.payload.subjects;
        this.listSessionScore = APIResponse.payload.students;
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }
      } catch (e) {
        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }

        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    async loadLecturer() {
      try {
        const response = await getLecturer(this.token);
        this.listLect = response.payload;
        if (response.token) {
          this.$store.commit("LOGGED_TOKEN", response.token);
        }
      } catch (e) {
        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    async deletesubject(sub) {
      const index = this.listSubject.indexOf(sub);
      this.deletItems = sub;
      const btnCancel = confirm("Are you sure you want to delete this item?");
      if (btnCancel === true) {
        this.listSubject.splice(index, 1);
        await this.deleteSubject();
      }
    },
    async deleteSubject() {
      try {
        await deleteSubject(this.deletItems.id);
      } catch (e) {
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
  },

  async mounted() {
    this.loadSubject();
    this.loadLecturer();
    this.checkSubjectName();
    this.loadSessionScore();
    this.sess_id = this.$route.params.id;
  },
};
</script>
<style scoped>
.v-data-table thead th {
  font-size: 16px !important;
  text-align: center !important;
}
.v-data-table tbody {
  text-align: center !important;
}

.btn_edit {
  background-color: #4e6470 !important;
  color: #fff !important;
  height: 35px;
}

.v-card__actions .v-btn.v-btn {
  padding: 0 16px;
}

.btn_create_new {
  color: #000000 !important;
  text-transform: capitalize !important;
  height: 40px !important;
  border: none !important;
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 14px;
}

.tab_wrapper {
  position: relative;
  display: inherit;
}

.v-tab {
  min-width: 30px;
  font-size: 15px;
  text-transform: capitalize;
}

.v-icon--left {
  margin-right: 0px;
}

.dropdown_transactions.v-btn {
  background-color: #2ca01c !important;
  height: 40px !important;
  width: auto !important;
  border-radius: 0 !important;
  padding: 0px 10px 0px 10px !important;
  color: #fff !important;
  right: 12px;
  position: absolute;
  top: 7px;
}

@media (max-width: 576px) {
}
</style>
